import React from 'react'
import nft_logo_footer from '../../../../images/mobileversion/logo.svg'
import lodi_footer from '../../../../images/OtherProjects/nft/lodi_footer.png'
import nonforprofit_footer from '../../../../images/OtherProjects/nft/nonforprofit_footer.png'
import mail from '../../../../images/OtherProjects/nft/mail.svg'
import mailY from '../../../../images/social-media/mail-yellow.svg'
import twitter from '../../../../images/OtherProjects/nft/tw.svg'
import twitterY from '../../../../images/social-media/tw-yellow.svg'
import inst from '../../../../images/OtherProjects/nft/insta.svg'
import instY from '../../../../images/social-media/inst-yellow.svg'
import tg from '../../../../images/OtherProjects/nft/tg.svg'
import tgY from '../../../../images/social-media/tg-yellow.svg'
import fb from '../../../../images/OtherProjects/nft/fb.svg'
import fbY from '../../../../images/social-media/fb-yellow.svg'
import '../../../../styles/NftCollection/NftCollectionFooter.css'

const NftCollectionFooter = () => {
  return (
    <div className='nft_footer_wrapper'>
      <div className='nft_footer_container'>
        <div>
          <div>
            <div>
              <img src={nft_logo_footer} alt="" />
            </div>
            <div className='footer_sub_title'>
              <span>The project to help families, children, medical institutions, military and other organizations.</span>
            </div>
          </div>
          <div>
            <div>
              <img src={lodi_footer} alt="" />
            </div>
            <div>
              <img src={nonforprofit_footer} alt="" />
            </div>
          </div>
          <ul className='social_media_nft'>
            <li>
              <a href="mailto:uadream22@gmail.com" target='_blank' rel="noreferrer">
                <img src={mail} alt="mail" />
                <img src={mailY} alt="mail" className='yellow_svg' />
              </a>

            </li>
            <li>
              <a href="https://twitter.com/mriya_ua" target='_blank' rel="noreferrer">
                <img src={twitter} alt="twitter" />
                <img src={twitterY} alt="twitter" className='yellow_svg' />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/mriyaukraine?utm_medium=copy_link" target='_blank' rel="noreferrer">
                <img src={inst} alt="instagram" />
                <img src={instY} alt="instagram" className='yellow_svg' />
              </a>
            </li>
            <li>
              <a href="https://t.me/mriyaUA22" target='_blank' rel="noreferrer">
                <img src={tg} alt="telegram" />
                <img src={tgY} alt="telegram" className='yellow_svg' />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/uamriya" target='_blank' rel="noreferrer">
                <img src={fb} alt="facebook" />
                <img src={fbY} alt="facebook" className='yellow_svg' />
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  )
}

export default NftCollectionFooter