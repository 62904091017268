import React from 'react'
import image1 from '../../../../images/AboutUs/image1.jpg'
import image2 from '../../../../images/AboutUs/image2.jpg'
import '../../../../styles/AboutUs/AboutUsFullScreen.css'

const AboutUsFullScreen = () => {
  return (
    <div className='about_us_container'>
      <div className='about_us_info'>
        <div className='text_block top'>
          <span>
            <span className='aboutUs_title' id='aboutUs'>МИ УКРАЇНЦІ</span>
            <br />
            Ми народилися в Україні і хочемо жити в нашій країні, мирно і безпечно.
            Кожен день ми робимо все можливе, щоб це сталося. Ми мріємо про мир і досягнемо цього разом.
            Як і всі в нашій великій і улюбленій країні, ми жили, працювали, будували плани, мріяли, прагнули до мети, вірили в світле майбутнє і
            чекали, коли все здійсниться. Але вранці 24 лютого 2022 року все це раптово закінчилося. Страх, біль, страждання і небезпека прийшли
            в нашу країну. Багато з нас були змушені покинути свої домівки і рятувати свої сім’ї. Багато людей втратили свої будинки, оскільки
            їх знищили або вони евакуювалися якомога швидше. Ті, хто залишився, були піддані випробуванням, яких ми нікому не побажали. Ті, хто поїхав,
            здебільшого йшли внікуди. Люди евакуювалися, як могли, евакуювали батьків, дітей, рідних, друзів.
          </span>
        </div>
        <div>
          <img src={image1} alt="Image1" />
        </div>
        <div>
          <img src={image2} alt="Image2" />
        </div>
        <div className='text_block bottom'>
          <span>
            Людина це неймовірна істота, яка досить швидко пристосовується. Ті, хто змогли швидше адаптуватися, направили свої сили на допомогу іншим.
            Також багато країн і міжнародних організацій направили свої ресурси на допомогу людям по всій країні.
            Ми також намагаємося зробити щось для наших громадян. Тому ми заснували проект <b>«МріяЮА»</b>, щоб допомогати сім’ям, дітям, медичним закладам,
            військовим та іншим організаціям. Як і всі інші українці, ми є співробітниками компаній, підприємцями. Отже, ми чітко розуміємо,
            наскільки жахлива ситуація зараз!
          </span>
        </div>
      </div>
    </div>
  )
}

export default AboutUsFullScreen