import React from 'react'
import pic6 from '../../../../images/OtherProjects/nft/pic6.png'
import nonforprofit from '../../../../images/OtherProjects/nft/nonforprofit.png'
import arrow from '../../../../images/OtherProjects/nft/arrow-snake.png'
import '../../../../styles/NftCollection/NftCollectionMain.css'



const NftCollectionEnMain = () => {
  return (
    <>
      <div className='nft_main_container'>
        <div>
          <div>
            <img src={pic6} alt="" />
          </div>
          <div>
            <img src={nonforprofit} alt="" />
            <div><span className='sub_title_support'>NFT TO SUPPORT UKRAINE</span></div>
            <div>
              <span className='sub_title_support2'>
                Toy descritipon. Lodi is a collection of cute toys, each of which is unique,
                has its own name and characteristics.They live in their own sweet world, make friends and exchange accessories.
              </span>
            </div>
            <div className='viewbox_main'>
              <a href="#">
                <span>VIEW ON MARKETPLACE</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='nft_main_wrapper'>
        <div className='nft_main_info_container'>
          <div>
            <span className='nft_main_info_title'>HOW YOU CAN SUPPORT UKRAINE</span>
          </div>
          <div className='nft_main_info_box'>
            <div className='nft_main_info_box_info'>
              <span className='box_info1'>01. Purchase NFT</span>
              <span className='box_info2'>The drop release date will be announced as soon as the project's quality and technical aspects
                are fully progressed. We also need to work hard with our community to make Discord and Twitter even
                cooler with new additions throughout time.
              </span>
            </div>
          </div>

          <div className='nft_main_info_box2'>
            <div className='nft_main_info_box_info'>
              <span className='box_info1'>02. Get metioned about your support</span>
              <span className='box_info2'>The drop release date will be announced as soon as the project's quality and technical aspects are fully progressed.</span>
            </div>
          </div>
          <div className='nft_main_info_box3'>
            <div className='nft_main_info_box_info3'>
              <span className='box_info1'>03. Get the report about your help</span>
              <span className='box_info2'>The drop release date will be announced as soon as the project's quality and technical aspects are fully progressed.</span>
              <div>
                <div className='box3_photo box3_biggest'></div>
                <div className='box3_photo box3_smallest'></div>
                <div className='box3_photo box3_middle'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='nft_pictures_row'>
        {Array.from({ length: 16 }).map((el, index) => {
          return (
            <img src={process.env.PUBLIC_URL + require(`../../../../images/rows/img${index + 1}.png`)} alt="" />
          )
        })}
      </div>
      <div className='viewbox_main_container'>
        <div className='viewbox_main'>
          <a href="#">
            <span>VIEW ON MARKETPLACE</span>
          </a>
        </div>
      </div>

    </>
  )
}

export default NftCollectionEnMain