import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EnglishVersion from './components/English/English';
import UkrainianVersion from './components/Ukrainian/Ukrainian';
import NftCollectionEn from './components/English/NftCollection/NftCollectionEn';
import NftCollectionUkr from './components/Ukrainian/NftCollection/NftCollectionUkr';
import './App.css';
import PrivacyPolicy from './components/English/PrivacyPolicy/PrivacyPolicy';




function App() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<UkrainianVersion />} />
        <Route path='/en' element={<EnglishVersion />} />
        <Route path='/en/nft' element={<NftCollectionEn />} />
        <Route path='/nft' element={<NftCollectionUkr />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
