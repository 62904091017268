//import { makeStyles, MenuItem, Select } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.svg';
import mail from '../../../images/social-media/mail.svg'
import mailY from '../../../images/social-media/mail-yellow.svg'
import twitter from '../../../images/social-media/tw.svg'
import twitterY from '../../../images/social-media/tw-yellow.svg'
import inst from '../../../images/social-media/insta.svg'
import instY from '../../../images/social-media/inst-yellow.svg'
import tg from '../../../images/social-media/tg.svg'
import tgY from '../../../images/social-media/tg-yellow.svg'
import fb from '../../../images/social-media/fb.svg'
import fbY from '../../../images/social-media/fb-yellow.svg'
import './PrivacyPolicy.css';
import Footer from '../Footer/Footer';

const useStyles = makeStyles({
  select: {
    "& ul": {
      backgroundColor: "#FFFF",
    },
    "& li": {
      fontSize: 16,
      color: 'black',
    },
    "& li a": {
      textDecoration: 'none',
      color: 'black'
    }
  },
  selectDisplay: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    width: '92px',
    padding: '5px 15px',
    borderRadius: '25px',
    fontFamily: 'Montserrat',
    fontWeight: '800',
    '& a': {
      color: 'white',
      fontFamily: 'Montserrat',
      fontWeight: '800',
      textDecoration: 'none'

    },
    '& svg': {
      color: '#FFFFFF',
      paddingRight: '10px',
    }
  }
});

const PrivacyPolicy = () => {
  const classes = useStyles();
  const [lang, setLang] = useState('ENG');

  const handleChange = (e) => {
    setLang(e.target.value)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <div className='pr_header_wrapper'>
        <div className='container'>
          <ul className='pr_header_components'>
            <li>
              <img src={logo} alt='Logo' />
            </li>
            <li>
              <Select
                labelId="demo-simple-select-label"
                id="select"
                value={lang}
                label="Age"
                className={classes.selectDisplay}
                MenuProps={{ classes: { paper: classes.select } }}
                SelectDisplayProps={classes.display}
                variant='standard'
                onChange={handleChange}
                disableUnderline
              >
                <MenuItem value={'UKR'}><Link to='/'>UKR</Link> </MenuItem>
                <MenuItem value={'ENG'}><Link to='/en'>ENG</Link></MenuItem>
              </Select>
            </li>
          </ul>
        </div>
      </div >
      <div className='pr_context_container'>
        <div className='pr_context_title'>
          <span>PRIVACY POLICY</span>
        </div>
        <div>
          <div className='pr_context_main'>
            <div>
              <span className='pr_title_main_parts'>A. Introduction</span>
              <span>
                1. The privacy of our website visitors is very important to us, and we are committed to safeguarding it. This policy explains what we do with your personal information.
              </span>
              <span>
                2. Consenting to our use of cookies in accordance with the terms of this policy when you first visit our website permits us to use cookies every time you visit our website.
              </span>
            </div>
            <div>
              <span className='pr_title_main_parts'>B. How we collect your personal data</span>
              <span className='pr_sub_title_main_part'>
                The following types of personal information may be collected, stored, and used:
              </span>
              <span>
                1. Information about your computer including your IP address, geographical location, browser type and version, and operating system.
              </span>
              <span>
                2. Information about your visits to and use of this website including the referral source, length of visit, page views, and website navigation paths.
              </span>
              <span>
                3. Information that you enter when you register with our website, such as your email and phone number.
              </span>
              <span>
                4. Information that you enter when you complete a form on our website. For example, your name, email, phone number, details about where are you from and where do you leave now, details about your needs.
              </span>
              <span>
                5. Information that you enter in order to set up subscription to our emails and/or newsletters.
              </span>
              <span>
                6. Information that is generated while using our website, including when, how often, and under what circumstances you use it.
              </span>
              <span>
                7. Information that you post to our website with the intention of publishing it on the internet.
              </span>
              <span>
                8. Any other personal information you send to us.
              </span>
            </div>
            <div>
              <span className='pr_title_main_parts'>C. Using Personal Information </span>
              <br />
              <span className='pr_sub_title_main_part'>
                Personal information submitted to us through our website will be used for the purposes specified in this policy or on the relevant pages of the website. We may use your personal information for the following:
              </span>
              <span>
                1. Administering our website.
              </span>
              <span>
                2. Personalizing our website for you.
              </span>
              <span>
                3. Enabling your use of the services available on our website
              </span>
              <span>
                4. Sending you on-marketing commercial communications.
              </span>
              <span>
                5. Sending you email notifications you have specifically requested.
              </span>
              <span>
                6. Sending you our email newsletter if you signed up for it (you can unsubscribe at any time).
              </span>
              <span>
                7. Sending you marketing communications relating to our business or the businesses of third parties which we think may be of interest to you.
              </span>
              <span>
                8. Providing third parties with statistical information about our users.
              </span>
              <span>
                9. Providing detailed information about users to certified parties.
              </span>
              <span>
                10. Dealing with inquiries and complaints made by or about you relating to our website.
              </span>
              <span>
                11. Keeping our website secure and prevent fraud.
              </span>
              <span>
                12. Verifying compliance with the terms and conditions governing the use of our website.
              </span>
              <span>
                13. Other uses.
              </span>
              <div className='pr_c_footer'>
                <span>
                  If you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the license you grant us.
                </span>
                <span>
                  Your privacy settings can be used to limit the publication of your information on our website and can be adjusted using privacy controls on the website.
                </span>
                <span>
                  We will not, without your expressed consent, supply your personal information to any third party for their or any other third party’s direct marketing.
                </span>
              </div>
            </div>
            <div>
              <span className='pr_title_main_parts'>D. Disclosing personal information  </span>
              <br />
              <span className='pr_sub_title_main_part'>
                We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors as reasonably necessary for the purposes set out in this policy.
              </span>
              <span className='pr_sub_title_main_part'>
                We may disclose your personal information:
              </span>
              <span>
                1. to the extent that we are required to do so by law;
              </span>
              <span>
                2. in connection with any ongoing or prospective legal proceedings;
              </span>
              <span>
                3. in order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);
              </span>
              <span>
                4. to the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling; and
              </span>
              <span>
                5. to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.
              </span>
              <div className='pr_c_footer'>
                <span>
                  Except as provided in this policy, we will not provide your personal information to third parties.
                </span>
              </div>
            </div>
            <div>
              <span className='pr_title_main_parts'>E. International data transfers</span>
              <br />
              <span className='pr_sub_title_main_part'>
                We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors as reasonably necessary for the purposes set out in this policy.
              </span>
              <span className='pr_sub_title_main_part'>
                We may disclose your personal information:
              </span>
              <span>
                1. Information that we collect may be stored, processed in, and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this policy.
              </span>
              <span>
                2. Information that we collect may be transferred to the following countries which do not have data protection laws equivalent to those in force in the European Economic Area: the United States of America, Russia, Japan, China, and India.
              </span>
              <span>
                3. Personal information that you publish on our website or submit for publication on our website may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others
              </span>
              <span>
                4. You expressly agree to the transfers of personal information described in this Section E.
              </span>
            </div>
            <div>
              <span className='pr_title_main_parts'>F. Retaining personal information </span>
              <span>
                1. This Section F sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations regarding the retention and deletion of personal informatio
              </span>
              <span>
                2. Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.
              </span>
              <span>
                3. Notwithstanding the other provisions of this Section F, we will retain documents (including electronic documents) containing personal data:
                <div className='sub_points'>
                  <span>a. to the extent that we are required to do so by law; </span>
                  <span>b. if we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and </span>
                  <span>in order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk). </span>
                </div>
              </span>
            </div>
            <div>
              <span className='pr_title_main_parts'>G. Security of your personal information</span>
              <span>
                1. We will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information.
              </span>
              <span>
                2. We will store all the personal information you provide on our secure (password- and firewall-protected) servers.
              </span>
              <span>
                3. You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.
              </span>
              <span>
                4.You are responsible for keeping the password you use for accessing our website confidential; we will not ask you for your password (except when you log in to our website).
              </span>
            </div>
            <div>
              <span className='pr_title_main_parts'>H. Amendments </span>
              <span style={{ margin: '1rem 0' }}>
                We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you understand any changes to this policy. We may notify you of changes to this policy by email or through the private messaging system on our website.
              </span>
            </div>
            <div>
              <span className='pr_title_main_parts'>I. Your rights </span>
              <span className='pr_sub_title_main_part'>
                You may instruct us to provide you with any personal information we hold about you; provision of such information will be subject to the following:
              </span>
              <span>
                1. the supply of appropriate evidence of your identity for this purpose, we will usually accept a photocopy of your passport certified by a notary plus an original copy of a utility bill showing your current address.
              </span>
              <div className='pr_c_footer'>
                <span>
                  We may withhold personal information that you request to the extent permitted by law.
                </span>
                <span>
                  You may instruct us at any time not to process your personal information for marketing purposes.
                </span>
                <span>
                  In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.
                </span>
              </div>
            </div>
            <div>
              <span className='pr_title_main_parts'>J. Third party websites  </span>
              <span className='pr_sub_title_main_part'>
                Our website includes hyperlinks to, and details of, third party websites. We have no control over, and are not responsible for, the privacy policies and practices of third parties.
              </span>
            </div>
            <div>
              <span className='pr_title_main_parts'>K. Updating information   </span>
              <span className='pr_sub_title_main_part'>
                Please let us know if the personal information that we hold about you needs to be corrected or updated.
              </span>
            </div>
            <div>
              <span className='pr_title_main_parts'>L. Cookies</span>
              <span className='pr_sub_title_main_part'>
                Our website uses cookies. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies. We use both session and persistent cookies on our website.
              </span>
              <span>
                1. The names of the cookies that we use on our website, and the purposes for which they are used, are set out below:
                <div className='sub_points'>
                  <span>a. we use Google Analytics on our website to recognize a computer when a user visits the website / track users as they navigate the website / enable the use of a form on the website / improve the website’s usability / analyze the use of the website / administer the website / prevent fraud and improve the security of the website / personalize the website for each user / target advertisements which may be of particular interest to specific users;  </span>
                </div>
              </span>
              <span>
                2. Most browsers allow you to refuse to accept cookies—for example:
                <div className='sub_points'>
                  <span>a. in Internet Explorer (version 10) you can block cookies using the cookie handling override settings available by clicking “Tools,” “Internet Options,” “Privacy,” and then “Advanced”;</span>
                  <span>b. in Firefox (version 24) you can block all cookies by clicking “Tools,” “Options,” “Privacy,” selecting “Use custom settings for history” from the drop-down menu, and unticking “Accept cookies from sites”; and </span>
                  <span>c. in Chrome (version 29), you can block all cookies by accessing the “Customize and control” menu, and clicking “Settings,” “Show advanced settings,” and “Content settings,” and then selecting “Block sites from setting any data” under the “Cookies” heading.  </span>
                </div>
                <div style={{ marginTop: '1rem' }}>Blocking all cookies will have a negative impact upon the usability of many websites. If you block cookies, you will not be able to use all the features on our website. </div>
              </span>
              <span>
                3. You can delete cookies already stored on your computer—for example:
                <div className='sub_points'>
                  <span>a. in Internet Explorer (version 10), you must manually delete cookie files you can find instructions for doing so at <a href="http://support.microsoft.com/kb/278835"><span style={{ display: 'inline' }}>support.microsoft.com"</span> </a>   </span>
                  <span>b. in Firefox (version 24), you can delete cookies by clicking “Tools,” “Options,” and “Privacy”, then selecting “Use custom settings for history”, clicking “Show Cookies,” and then clicking “Remove All Cookies”; and ;  </span>
                  <span>c. in Chrome (version 29), you can delete all cookies by accessing the “Customize and control” menu, and clicking “Settings,” “Show advanced settings,” and “Clear browsing data,” and then selecting “Delete cookies and other site and plug-in data” before clicking “Clear browsing data.” </span>
                </div>
                <div style={{ marginTop: '0.5rem' }}>Deleting cookies will have a negative impact on the usability of many websites.</div>
              </span>

            </div>
            <div>
              <span className='pr_title_main_parts'>M. Questions or Complaints</span>
              <span style={{ margin: '1rem 0' }}>
                If you have questions or wish to file a complaint, please email us at uadream22@gmail.com
              </span>
              <span style={{ margin: '1rem 0' }}>
                This Privacy Policy was last updated on Jule 19, 2022.
              </span>
            </div>
          </div>
        </div>
      </div >
      <div style={{ height: '30px' }}></div>
      <Footer />



    </>

  )
}

export default PrivacyPolicy