import React from 'react'
import logo_nft from '../../../../images/mobileversion/logo.svg'
import lodi from '../../../../images/OtherProjects/nft/lodi.png'
import mail from '../../../../images/OtherProjects/nft/mail.svg'
import mailY from '../../../../images/social-media/mail-yellow.svg'
import twitter from '../../../../images/OtherProjects/nft/tw.svg'
import twitterY from '../../../../images/social-media/tw-yellow.svg'
import inst from '../../../../images/OtherProjects/nft/insta.svg'
import instY from '../../../../images/social-media/inst-yellow.svg'
import tg from '../../../../images/OtherProjects/nft/tg.svg'
import tgY from '../../../../images/social-media/tg-yellow.svg'
import fb from '../../../../images/OtherProjects/nft/fb.svg'
import fbY from '../../../../images/social-media/fb-yellow.svg'
import pic1 from '../../../../images/OtherProjects/nft/pic1.png'
import pic2 from '../../../../images/OtherProjects/nft/pic2.png'
import pic3 from '../../../../images/OtherProjects/nft/pic3.png'
import pic4 from '../../../../images/OtherProjects/nft/pic4.png'
import pic5 from '../../../../images/OtherProjects/nft/pic5.png'
import arrow from '../../../../images/OtherProjects/nft/arrow.svg'

const NftCollectionEnHeader = () => {
  return (
    <div className='header_wrapper_nft'>
      <div className='header_container_nft'>
        <div>
          <div>
            <img src={logo_nft} alt="" />
          </div>
          <ul className='social_media_nft'>
            <li>
              <a href="mailto:uadream22@gmail.com" target='_blank' rel="noreferrer">
                <img src={mail} alt="mail" />
                <img src={mailY} alt="mail" className='yellow_svg' />
              </a>

            </li>
            <li>
              <a href="https://twitter.com/mriya_ua" target='_blank' rel="noreferrer">
                <img src={twitter} alt="twitter" />
                <img src={twitterY} alt="twitter" className='yellow_svg' />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/mriyaukraine?utm_medium=copy_link" target='_blank' rel="noreferrer">
                <img src={inst} alt="instagram" />
                <img src={instY} alt="instagram" className='yellow_svg' />
              </a>
            </li>
            <li>
              <a href="https://t.me/mriyaUA22" target='_blank' rel="noreferrer">
                <img src={tg} alt="telegram" />
                <img src={tgY} alt="telegram" className='yellow_svg' />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/uamriya" target='_blank' rel="noreferrer">
                <img src={fb} alt="facebook" />
                <img src={fbY} alt="facebook" className='yellow_svg' />
              </a>
            </li>
          </ul>
        </div>
        <div className='lodi_block'>
          <img src={lodi} alt="" />
          <div><span>NON FOR PROFIT COLLECTION</span></div>
          <div className='lodi_description'><span>Lodi is a collection of NFP NFT avatars created to support Ukraine. All funds from sales are transferred to humanitarian aid provided by MriyaUA.</span></div>
        </div>
        <div className='images_box_nft'>
          <div><img src={pic1} alt="" /></div>
          <div><img src={pic2} alt="" /></div>
          <div><img src={pic3} alt="" /></div>
          <div><img src={pic4} alt="" /></div>
          <div><img src={pic5} alt="" /></div>
        </div>
        <div className='header_bottom_nft'>
          <div className='viewbox'>
            <a href="#">
              <span>VIEW ON MARKETPLACE</span>
            </a>
          </div>
          <img src={arrow} alt="" className='arrow_nft'/>
        </div>
      </div>
    </div>
  )
}

export default NftCollectionEnHeader