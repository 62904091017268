import React from 'react'
import '../../../styles/NftCollection/NftCollectionFull.css'
import NftCollectionEnHeader from './NftCollectionEnHeader/NftCollectionEnHeader'
import NftCollectionEnMain from './NftCollectionEnMain/NftCollectionEnMain'
import NftCollectionFooter from './NftCollectionFooter/NftCollectionFooter'

const NftCollectionEn = () => {
  return (
    <>
      <NftCollectionEnHeader />
      <NftCollectionEnMain />
      <NftCollectionFooter />
    </>
  )
}

export default NftCollectionEn